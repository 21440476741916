import { useQuery } from "@apollo/client";
import { Flex, Slider } from "antd";
import { FanPageContext } from "apps/PublicPage/context";
import {
  PUBLIC_EPISODE_MEMBER_QUERY,
  PUBLIC_EPISODE_NON_MEMBER_QUERY,
} from "apps/PublicPage/graphql";
import {
  A,
  Card,
  CardInner,
  CardTitle,
  ErrorCard,
  Link,
  SkeletonCard,
  Span,
  Row,
  Col,
  Image,
} from "atoms";
import { CardDescription } from "atoms/Card";
import { FaChevronLeft } from "icons";
import AudioPlayerDark from "apps/PublicPage/components/AudioPlayerDark";
import EpisodeDetailSlider from "libraries/Audio/components/AudioRecord/components/EpisodeDetailSlider";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { EpisodeType } from "types";
import { formatFromIos, formatSameAsISOTime } from "utils/Datetime";
import BecomeMember from "apps/PublicPage/components/BecomeMember";
const EpisodeDescription = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const ModalInner = styled.div`
  padding: 0 0 20px; 
`;

type Props = {
  refetchShowQuery: Function;
};

const EpisodeDetails = (props: { episode: EpisodeType, referrer }) => {
  const { refetchShowQuery } = props;
  const { isMember, show } = useContext(FanPageContext);
  const { isMobile } = useContext(ThemeContext);
  const { episode } = props;
  const { referrer } = props;

  return (
    <Row
      className="EpisodeDetails"
      style={{ maxWidth: "100%", margin: "0 auto" }}
    >
      <Col span={24} className="mb-3">
        <Link to={referrer.referrer === `/:slug` ? `/${show.slug}` : `/${show.slug}/podcast`}>
          <Flex align="center" gap={10}>
            <FaChevronLeft />
            <Span>Back to List</Span>
          </Flex>
        </Link>
      </Col>

      <Card shadow style={{ width: "100%" }}>
        <CardInner>
          <Col
            span={24}
            style={{
              backgroundColor: "white",
              width: "100%",
              paddingBottom: "30px",
            }}
          >
            <Col
              className="mb-3 post-title"
              style={{
                maxWidth: "680px",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                paddingTop: "30px",
                marginBottom: "25px",
                paddingLeft: isMobile ? "5px" : "0px",
              }}
            >
              <CardTitle
                style={{ maxWidth: "680px", marginBottom: "8px" }}
                css={`
                  font-size: ${isMobile ? "17px;" : "22px;"};
                `}
              >
                {episode.title}
              </CardTitle>
              <Col
                style={{
                  maxWidth: "680px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <CardDescription
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: isMobile ? "13px" : "15px",
                  }}
                  grey
                >
                  {formatSameAsISOTime(episode.publishAt)}
                </CardDescription>
              </Col>
            </Col>
            <Row
              flex
              gutter={10}
              style={{
                maxWidth: "680px",
                flexDirection: isMobile ? "column" : "row",
                margin: "0px auto",
                marginBottom: "25px",
              }}
            >
              <Col
                style={{
                  paddingBottom: "0px",
                  marginBottom: isMobile ? "10px" : "0",
                  height: "130px",
                  objectFit: "cover",
                  display: isMobile ? "flex" : "block",
                  justifyContent: isMobile ? "center" : "flex-start",
                }}
              >
                <Image
                  width="130px"
                  fullHeight
                  src={episode.customArtworkUrl || show.podcast.coverArtUrl}
                  radius="5px"
                />
              </Col>
              <Col className="player" flex={4}>
                <Card
                  simple
                  shadow
                  css="background-color: #000000; height: 130px;"
                >
                  <CardInner style={{ padding: "0px 0px" }}>
                    <Col
                      style={{
                        paddingLeft: isMobile ? "2px" : "7px",
                        paddingRight: isMobile ? "2px" : "15px",
                        marginTop: "10px",
                      }}
                    >
                      {isMember ? (
                        <Col
                          style={{
                            paddingTop: isMobile ? "6px" : "28px",
                            paddingBottom: isMobile ? "6px" : "28px",
                            paddingLeft: isMobile ? "0px" : "15px",
                            paddingRight: isMobile ? "0" : "5px",
                          }}
                        >
                          <AudioPlayerDark
                            src={episode.audioUrl}
                            duration={episode.audioDuration}
                            simple
                          />
                        </Col>
                      ) : (
                        <Col
                          style={{
                            paddingRight: isMobile ? "4px" : "8px",
                            paddingLeft: isMobile ? "8px" : "20px",
                          }}
                        >
                          <EpisodeDetailSlider
                            simple
                            src={episode.audioUrl}
                            duration={episode.audioDuration}
                            showSubscribeMessage={false}
                          />
                        </Col>
                      )}
                    </Col>
                  </CardInner>
                </Card>
              </Col>
            </Row>
            {isMember ? (
              <Col span={24}>
                <Span
                  className="post-description"
                  dangerouslySetInnerHTML={{ __html: episode?.description }}
                  style={{
                    marginTop: "10px",
                    maxWidth: "680px",
                    width: "100%",
                    margin: "0 auto",
                    display: "block",
                    fontSize: isMobile ? "14px" : "16px",
                    lineHeight: "1.6",
                  }}
                />
                <style>
                  {`
                  .post-description p {
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                  .post-description blockquote {
                    border-left: 3px solid #bbb;
                    padding-left: 15px;
                    margin: 10px 0;
                    color: #444;
                  }
                `}
                </style>
              </Col>
            ) : (
              <></>
            )}
          </Col>
        </CardInner>
      </Card>
      {!isMember ? (
        <Col span={24}>
          <Col span={24} align="middle">
            <Span
              color="black"
              style={{
                paddingTop: "20px",
                paddingBottom: "8px",
                maxWidth: "425px",
                justifyContent: "flex-start",
                display: "flex",
                whiteSpace: "nowrap",
                fontSize: isMobile ? "18px" : "20px",
              }}
            >
              Subscribe to Unlock This Episode
            </Span>
          </Col>
          <Card
            simple
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              marginTop: "10px",
              maxWidth: "425px",
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ModalInner
              style={{
                maxWidth: "680px",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <BecomeMember show={show} refetchShowQuery={refetchShowQuery} />
            </ModalInner>
          </Card>
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
};

const WithData = (referrer) => {
  const { isMember } = useContext(FanPageContext);
  const { uuid } = useParams<{ uuid: string }>();
  const { data: episodeMemberData, loading: eMemberLoading } = useQuery(
    PUBLIC_EPISODE_MEMBER_QUERY,
    {
      variables: { uuid },
      skip: !isMember,
    }
  );
  const { data: episodeNonMemberData, loading: eNonMemberLoading } = useQuery(
    PUBLIC_EPISODE_NON_MEMBER_QUERY,
    {
      variables: { uuid },
      skip: isMember,
    }
  );
  const episode = useMemo(
    () =>
      isMember
        ? episodeMemberData?.publicEpisodeMember
        : episodeNonMemberData?.publicEpisodeNonMember,
    [isMember, episodeMemberData, episodeNonMemberData]
  );

  if (eMemberLoading || eNonMemberLoading) {
    return <SkeletonCard />;
  }

  if (!episode) {
    return <ErrorCard title="Something went wrong!" />;
  }

  return <EpisodeDetails episode={episode} referrer={referrer} />;
}

export default WithData;
