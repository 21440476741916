import { gql } from "@apollo/client";

export const superfanFields = gql`
  fragment superfanFields on SuperFan {
    totalCents
    unlockedAt
    showOnLeaderboard
    user {
      id
      displayName
      bio
      avatarUrl
      website
      twitter
      instagram
      isMembership
    }
  }
`;

export const membershipFields = gql`
  fragment membershipFields on Membership {
    id
    uuid
    fullName
    displayName
    contactId
    createdAt
    canceledAt
    cancelRequestedAt
    instagram
    twitter
    website
    nextBillAt
    amountCents
    avatarUrl
    status
    accessType
    isRecurring
    user {
      id
      email
    }
    tier {
      id
      active
      kind
      amountCents
    }
  }
`;

export const fanFields = gql`
  fragment fanFields on Fan {
    id
    fullName
    displayName
    createdAt
    instagram
    twitter
    website
    messageCount
    avatarUrl
    bio
    totalPaidCents
    status
    suspended
    user {
      id
      email
    }
    membership {
      ...membershipFields
    }
  }
  ${membershipFields}
`;

export const contactFields = gql`
  fragment contactFields on Contact {
    id
    fullName
    displayName
    email
    addedAt
    instagram
    twitter
    website
    messageCount
    avatarUrl
    bio
    totalPaidCents
    superfan
    isSubscribed
  }
`;

export const paymentMessageFields = gql`
  fragment paymentMessageFields on PaymentMessage {
    id
    content
    createdAt
    status
  }
`;

export const paymentReplyFields = gql`
  fragment paymentReplyFields on PaymentReply {
    id
    content
    createdAt
    status
    user {
      id
      displayName
      bio
      avatarUrl
      website
      twitter
      instagram
      isMembership
    }
  }
`;

export const collectionMetadataFields = gql`
  fragment collectionMetadataFields on CollectionMetadata {
    currentPage
    limitValue
    totalCount
    totalPages
  }
`;
