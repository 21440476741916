import React, {
  useMemo,
  useState,
} from "react";
import { withPublicPageState } from "apps/PublicPage/context";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

import { useParams, useHistory } from "react-router-dom";
import { useURLQuery } from "hooks";

import {
  Modal,
  Row,
  Col,
} from "atoms";
import message from "atoms/message";
import { AccountLockedModal } from "components/AccountLocked";
import Message from "../components/Message";
import { CANCELED, TRUE } from "consts";
import { MESSAGE_QUERY } from "apps/Dashboard/graphql";
import {
  SShow,
  MessageContainer,
} from "../components/FanPage";
import FanPageHeader from "../containers/FanPageHeader";
import FanPageMain from "../containers/FanPageMain";

const SModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px !important;
  }
`;

const FanPage = ({
  show,
  publicPageState: { openAuthModal, isLoggedIn },
}) => {
  const { slug } = useParams();

  const [accountLockedVisible, setAccountLockedVisible] = useState(false);

  const { mid = null, open = false } = useURLQuery();
  const modalVisible = useMemo(() => open === TRUE, [open]);
  
  const { data: messageData, error } = useQuery(MESSAGE_QUERY, {
    variables: {
      id: mid,
      showId: show.id,
      showSlug: slug,
    },
    skip: !mid
  });

  const modalMessage = useMemo(
    () => messageData?.message || null,
    [messageData]
  );

  const bannerImage = useMemo(() => show?.bannerImageUrl || null, [show]);

  const isCanceled = useMemo(() => show?.status === CANCELED, [show]);
  const history = useHistory();

  const handleCloseModal = () => {
    history.push(history.location.pathname);
  };

  // Show error message if query fails
  if (error) {
    message.error("Message unavailable");
    history.push(history.location.pathname);
  }

  return (
    <SShow hasBanner={!!bannerImage}>
      <div style={{ marginBottom: '30px' }}>
        <FanPageHeader show={show} />
      </div>
      {!isCanceled && show.active && (
        <FanPageMain />
      )}
      <AccountLockedModal
        open={accountLockedVisible}
        onCancel={() => setAccountLockedVisible(false)}
      />
      {modalMessage && modalVisible && (
        <SModal
          open={modalVisible}
          noBodyPadding
          width={700}
          overflowHidden
          onCancel={handleCloseModal}
        >
          <Row>
            <Col flex={`1 0 100%`}>
              <MessageContainer>
                <Message
                  message={modalMessage}
                  slug={slug}
                  initialRepliedMessageVisible
                  tipsList={false}
                />
              </MessageContainer>
            </Col>
          </Row>
        </SModal>
      )}
    </SShow>
  );
};

export default withPublicPageState(FanPage);
