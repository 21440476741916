import {
  A,
  afterModalClose,
  Amount,
  Card,
  CardDescription,
  CardImage,
  CardInner,
  CardTitle,
  Col,
  Div,
  message,
  Paragraph,
  Row,
  Span,
  Link,
  Modal,
  ModalTitle,
  AsideHelper,
  ModalScrollInner,
} from "atoms";
import React, { useContext, useState } from "react";
import { PublicPerkType, User } from "types";
import { useMemo } from "react";
import { useEffect } from "react";
import { PublicPageContext } from "../context";
import { SIGNUP } from "consts";
import styled from "styled-components";
import { StyledSwiper, SwiperSlide } from "utils/swiperConfig";
import { FaUnLock } from "icons";
import SuperfanList from "./SuperfanList";
import { useRouteMatch } from 'react-router-dom';

const NoImageCard = styled(CardImage)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const CardWrapper = styled(Card)`
  min-height: 350px;
`;
type PropsType = {
  perk: PublicPerkType;
  superfansPluralName: string;
  superfansSingularName: string;
  show_slug: string;
  imageOptional?: boolean;
  showPurchasedFansOnPerk: boolean;
  showPurchasesNumberOnPerk: boolean;
  onPerkCreate: () => void;
  me: User;
};

const PerkCard = (props: PropsType): JSX.Element => {
  const {
    perk,
    imageOptional,
    superfansSingularName,
    superfansPluralName,
    showPurchasedFansOnPerk,
    showPurchasesNumberOnPerk,
    me,
    show_slug,
  } = props;

  const { superfans } = perk;
  const purchasesNumber = superfans.length;

  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const [superfansModalVisible, setSuperfansModalVisible] =
    useState<boolean>(false);

  const openModal = (e) => {
    e.preventDefault();
    setSuperfansModalVisible(true);
  };

  const superfansBlock = useMemo(
    () =>
      perk ? (
          <Div css="font-size: 0.8rem;" className="pt-1 pb-1">
            <span style={{fontWeight: 500}}>
              {perk.purchasesNumber}{" "}
              {perk.purchasesNumber > 1
                ? superfansPluralName
                : superfansSingularName}
            </span>
            &nbsp;
            <Span color="contentGrey">got this item</Span>
          </Div>
      ) : '',
    [perk]
  );

  const renderImages = (perk) => {
    if (perk.perkImages.length > 1) {
      return (
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{ clickable: true }}
          navigation={true}
        >
          {perk.perkImages.map((image, index) => (
            <SwiperSlide key={image.id}>
              <CardImage image={image.imageUrl} aspectRatio={2} />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      );
    } else if (perk.perkImages.length === 1) {
      return <CardImage image={perk.perkImages[0].imageUrl} aspectRatio={2} />;
    } else {
      return (
        <NoImageCard image="" aspectRatio={2}>
          <Amount
            color="green"
            medium
            css={"position: absolute;  bottom: 50%; top: 50%;"}
          >
            {perk.amountCents === 0 ? "FREE" : `$${perk.amountCents / 100}`}
          </Amount>
        </NoImageCard>
      );
    }
  };
  const { url } = useRouteMatch();

  return (
    <Card simple shadow css="display: flex; flex-direction: column; height: 100%; ">
      <Link to={`${url.replace('/shop', '')}/shop/${perk.uuid}`} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Div css={'display: block;'}>
          {renderImages(perk)}
        </Div>
        <CardInner thin css="flex-grow: 1;">
          <Row className={'mb-1'} align="top" gutter={10} style={{ alignItems: "baseline" }}>
            <Col flex="1 0 0">
              <CardTitle
                style={{
                  color: "#000000",
                  textTransform: "none",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {perk.title}
              </CardTitle>
            </Col>
            <Col style={{ whiteSpace: "nowrap" }}>
              <Amount color="green" medium>
                {perk.amountCents === 0 ? "FREE" : `$${perk.amountCents / 100}`}
              </Amount>
            </Col>
          </Row>
          <CardDescription
            css="margin-bottom: 10px"
            ignoreLineBreak={!summaryExpanded}
          >
            <Paragraph
              ellipsis={{
                rows: 4,
                expandable: true,
                symbol: "read more",
                onExpand: () => setSummaryExpanded(true),
              }}
              className="mb-0"
              css={`
                color: #777;
              `}
            >
              {perk.summary}
            </Paragraph>
          </CardDescription>
        </CardInner>
      </Link>
      {showPurchasesNumberOnPerk && showPurchasedFansOnPerk && !perk.unlocked && (
        <CardInner thin css="flex-grow: 0; border-top: 1px solid var(--light-grey);">
          <Div css="font-size: 0.8rem;" className="pt-1 pb-1">
            {perk.purchasesNumber >= 1 ? (
              <A block color="content" onClick={(e) => openModal(e)}>
                <span style={{ fontWeight: 500 }}>
                  {perk.purchasesNumber}{" "}
                  {perk.purchasesNumber === 1
                    ? superfansSingularName
                    : superfansPluralName}
                </span>
                &nbsp;
                <Span color="contentGrey">purchased this</Span>
              </A>
            ) : (
              <span style={{ fontWeight: 500 }}>
                {perk.purchasesNumber}{" "}
                {perk.purchasesNumber === 1
                  ? superfansSingularName
                  : superfansPluralName}
              &nbsp;
              <Span color="contentGrey" style={{ fontSize: '0.8rem' }}>
                purchased this
              </Span>
              </span>
              )}
          </Div>
        </CardInner>
      )}

      <Modal
        open={superfansModalVisible}
        simple
        onCancel={() => setSuperfansModalVisible(false)}
        centered
        width="450px"
        closable={false}
        footer={null}
        title={
          <>
            <ModalTitle>
              {perk.purchasesNumber}{" "}
              {perk.purchasesNumber > 1
                ? superfansPluralName
                : superfansSingularName}
            </ModalTitle>
            <AsideHelper single>purchased this perk</AsideHelper>
          </>
        }
      >
        <ModalScrollInner css="max-height: calc(100vh - 85px - 45px * 2);">
          <SuperfanList superfans={perk.superfans} sortCompare={false} />
        </ModalScrollInner>
      </Modal>
      {showPurchasesNumberOnPerk && !showPurchasedFansOnPerk && !perk.unlocked && (
        <CardInner thin css="flex-grow: 0; border-top: 1px solid var(--light-grey);">
          <Div css="font-size: 0.8rem;" className="pt-1 pb-1">
            <span style={{ fontWeight: 500 }}>
              {perk.purchasesNumber}{" "}
              {perk.purchasesNumber > 1
                ? superfansPluralName
                : superfansSingularName}
            </span>
            &nbsp;
            <Span color="contentGrey">purchased this</Span>
          </Div>
        </CardInner>
      )}
      {showPurchasesNumberOnPerk && showPurchasedFansOnPerk && perk.unlocked && (
        <CardInner thin css="flex-grow: 0;border-top: 1px solid var(--light-grey);">
          <Div css="font-size: 0.8rem;" className="pt-1 pb-1">
            <Link to={`/${show_slug}/shop/${perk.uuid}`} target="_blank">
              <FaUnLock color="black" />
              &nbsp;
              <span style={{ fontWeight: 500, color: "black" }}>
                view purchased item
              </span>
            </Link>
          </Div>
        </CardInner>
      )}
      {showPurchasesNumberOnPerk && !showPurchasedFansOnPerk && perk.unlocked && (
        <CardInner thin css="flex-grow: 0;border-top: 1px solid var(--light-grey);">
          <Div css="font-size: 0.8rem;" className="pt-1 pb-1">
            <FaUnLock color="black" />
            &nbsp;
            <span style={{ fontWeight: 500, color: "black" }}>
              view purchased item
            </span>
          </Div>
        </CardInner>
      )}
    </Card>
  );
};

export default PerkCard;