import { gql } from "@apollo/client";
import { membershipFields } from "graphql-api";

export const MY_FOLLOWINGS_QUERY = gql`
  query myFollowings {
    myFollowings {
      id
      show {
        id
        slug
        title
        coverImageUrl
      }
      membership {
        ...membershipFields
      }
    }
  }
  ${membershipFields}
`;
