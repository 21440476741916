import React, { useMemo, useState } from "react";
import { withTheme } from "styled-components";
import { useMutation, useQuery } from '@apollo/client';

import { Image, Row, Col, Popover, Div, Button, Modal, message } from "atoms";
import { Flex } from "antd";
import { socialLinks } from "micro-data";
import ListenConnects from "../components/ListenConnects";
import { withPublicPageState } from 'apps/PublicPage/context';

import {
  ImageContainer,
  PageTitle,
  HeaderBanner,
  ListenButton,
  ListenPopoverInner,
  MobilePageTitle,
} from "../components/FanPage";
import { FaHeadphones, FaHeart, FaLink, FaSHeart, FaHeartBroken, FaExtraLinkAlt } from "icons";
import { FAN_QUERY, FOLLOW_SHOW_MUTATION, UNFOLLOW_SHOW_MUTATION, HAS_ACTIVE_SUBSCRIPTION_QUERY } from "graphql-api";

// Add global style for popover arrow
const style = document.createElement('style');
style.textContent = `
  .ant-modal-content {
    border-radius: 4px !important;
    font-family: 'Lexend Deca', sans-serif !important;
  }
  .ant-modal-cancel-button:hover {
    color: #000 !important;
    opacity: 0.8 !important;
    background-color: transparent !important;
  }

  .followed-icon {
    color: #ec304a !important;
    padding: 10px 15px !important;
  }

  .confirm-unfollow-btn {
    float: right;
    margin-left: auto;
  }
`;
document.head.appendChild(style);

const FanPageHeader = ({ 
  show, 
  theme: { isDesktop, isMobile },
  publicPageState: { isLoggedIn, me, openAuthModal }
}) => {

  const { data: fanData } = useQuery(FAN_QUERY, {
    variables: {
      userId: me?.id,
      showId: show.id
    },
    skip: !isLoggedIn || !me?.id || !show.id
  });

  const { data: subscriptionData } = useQuery(HAS_ACTIVE_SUBSCRIPTION_QUERY, {
    fetchPolicy: 'network-only',
    skip: !isLoggedIn,
    variables: { showId: show.id }
  });

  const [followShow] = useMutation(FOLLOW_SHOW_MUTATION);
  const [unfollowShow] = useMutation(UNFOLLOW_SHOW_MUTATION);
  const [unfollowShowModalOpen, setUnfollowShowModalOpen] = useState(false);
  const [unfollowErrorSubscriptionModalOpen, setUnfollowErrorSubscriptionModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  
  const isFan = !!fanData?.fan;
  const hasActiveSubscription = subscriptionData?.hasActiveSubscription;

  const handleFollowClick = async () => {
    if (!isLoggedIn) {
      openAuthModal('SIGNUP');
      return;
    }

    try {
      await followShow({
        variables: { showId: show.id },
        refetchQueries: [{ 
          query: FAN_QUERY,
          variables: { userId: me.id, showId: show.id }
        }]
      });
      message.success('Now Following');
    } catch (err) {
      message.error('Failed to follow');
    }
  };

  const handleUnfollowClick = async () => {
    if (!isLoggedIn) {
      openAuthModal('SIGNUP');
      return;
    }

    try {
      await subscriptionData?.refetch?.();
    } catch (err) {
      message.error(err.message);
      return;
    }
    if (hasActiveSubscription) {
      setUnfollowErrorSubscriptionModalOpen(true);
      return;
    }
    setUnfollowShowModalOpen(true);
  };

  const handleUnfollowConfirm = async () => {
    try {
      const { data } = await unfollowShow({
        variables: { showId: show.id },
        refetchQueries: [{
              query: FAN_QUERY,
              variables: { userId: me.id, showId: show.id }
          }, {
          query: HAS_ACTIVE_SUBSCRIPTION_QUERY,
        }]
      });
      if (data?.unfollowShow?.errors.length === 0) {
        message.success('Unfollowed');
      } else {
        if (data?.unfollowShow?.errors[0] === 'You can\'t unfollow with an active subscription') {
          setUnfollowErrorSubscriptionModalOpen(true);
        } else {
          message.error(data?.unfollowShow?.errors[0]);
        }
      }
      setUnfollowShowModalOpen(false);
    } catch (err) {
      message.error('Failed to unfollow');
    }
  };

  const renderFollowButton = () => {
    if (isFan) {
      return (
        <Popover
          trigger={["click"]}
          placement="bottomLeft"
          simple
          overlayInnerStyle={{ background: 'transparent', boxShadow: 'none', marginLeft: '-12px', marginTop: '-20px' }}
          arrow={false}
          open={isPopoverOpen}
          onOpenChange={(open) => setIsPopoverOpen(open)}
          content={
            <ListenPopoverInner>
              <ListenButton onClick={(e) => {
                e.stopPropagation();
                setIsPopoverOpen(false);
                handleUnfollowClick();
              }}>
                <Row gutter={5} align="middle">
                  <Col><FaHeartBroken /></Col>
                  <Col>Unfollow</Col>
                </Row>
              </ListenButton>
            </ListenPopoverInner>
          }
        >
          <ListenButton className="followed-icon" style={{ minWidth: '0px' }}>
            <Row gutter={5} align="middle">
              <Col><FaSHeart /></Col>
            </Row>
          </ListenButton>
        </Popover>
      );
    }

    return (
      <ListenButton onClick={handleFollowClick} style={{ minWidth: '0px' }}>
        <Row gutter={5} align="middle">
          <Col><FaHeart /></Col>
          <Col>Follow</Col>
        </Row>
      </ListenButton>
    );
  };

  const showLinks = useMemo(() => {
    return show
      ? [
        ...(show.email
          ? [
            {
              ...socialLinks.email,
              name: "email", 
              link: `mailto:${show.email}`,
            },
          ]
          : []),
        ...(show.website
          ? [
            {
              ...socialLinks.website,
              name: "website",
              link: `https://${show.website}`,
            },
          ]
          : []),
        ...(show.instagram
          ? [
            {
              ...socialLinks.instagram,
              name: "instagram",
              link: `https://${show.instagram}`,
            },
          ]
          : []),
        ...(show.twitter
          ? [
            {
              ...socialLinks.twitter,
              name: "twitter",
              link: `https://${show.twitter}`,
            },
          ]
          : []),
        ...(show.facebook
          ? [
            {
              ...socialLinks.facebook,
              name: "facebook",
              link: `https://${show.facebook}`,
            },
          ]
          : []),
        ...(show.linkedin
          ? [
            {
              ...socialLinks.linkedin,
              name: "linkedin",
              link: `https://${show.linkedin}`,
            },
          ]
          : []),
        ...(show.tiktok
          ? [
            {
              ...socialLinks.tiktok,
              name: "tiktok",
              link: `https://${show.tiktok}`,
            },
          ]
          : []),

        ...(show.applePodcast
          ? [
            {
              ...socialLinks.applePodcast,
              name: "applePodcast",
              link: `https://${show.applePodcast}`,
            },
          ]
          : []),
        ...(show.spotify
          ? [
            {
              ...socialLinks.spotify,
              name: "spotify",
              link: `https://${show.spotify}`,
            },
          ]
          : []),
        ...(show.youtube
          ? [
            {
              ...socialLinks.youtube,
              name: "youtube",
              link: `https://${show.youtube}`,
            },
          ]
          : []),
        ...(show.rssFeed
            ? [
              {
                ...socialLinks.rssFeed,
                name: "rssFeed",
                link: `https://${show.rssFeed}`,
              },
            ]
            : []),
      ]
      : [];
  }, [show]);

  const listenLinks = useMemo(
    () => showLinks.filter(
      // @ts-ignore
      (l) => !!l.listenOn
    ),
    [showLinks]
  );

  const normalLinks = useMemo(
    () => showLinks.filter(
      // @ts-ignore
      (l) => !l.listenOn
    ),
    [showLinks]
  );

  const bannerImage = useMemo(() => show?.bannerImageUrl || null, [show]);

  return (
    <div className="FanPageHeader">
      <HeaderBanner bannerImage={bannerImage}>
        {!isMobile && (
          <div>
            <Row align="bottom" gutter={20} style={{ display: 'flex', flexWrap: 'nowrap' }}>
              {show.coverImageUrl && (
                <Col>
                  <ImageContainer pushTop={0}>
                    <Image src={show.coverImageUrl} radius={"5px"} />
                  </ImageContainer>
                </Col>
              )}
              <Col>
                <PageTitle>{show.title}</PageTitle>
                  <Div>
                    <Row singleRow gutter={10}>
                      {normalLinks.length + listenLinks.length > 0 && (
                        <Col>
                          <Popover
                            trigger={["click"]}
                            placement="bottomLeft"
                            simple
                            content={
                              <ListenPopoverInner>
                                <Row gutter={12}>
                                  {
                                    listenLinks.length > 0 && (
                                      <Col>
                                        <ListenConnects
                                          title="Listen On"
                                          items={listenLinks}
                                        />
                                      </Col>
                                    )
                                  }
                                  {
                                    normalLinks.length > 0 && (
                                      <Col>
                                        <ListenConnects
                                          title="Connect"
                                          items={normalLinks}
                                        />
                                      </Col>
                                    )
                                  }
                                </Row>
                              </ListenPopoverInner>
                            }
                          >
                            <ListenButton>
                              <Row gutter={5} align="middle">
                                <Col>
                                  <FaExtraLinkAlt />
                                </Col>
                                <Col>links</Col>
                              </Row>
                            </ListenButton>
                          </Popover>
                        </Col>
                      )}
                    <Col>
                      {renderFollowButton()}
                    </Col>
                  </Row>
                </Div>
              </Col>
            </Row>
          </div>
        )}
      </HeaderBanner>
      {
        isMobile && (
          <div style={{ marginTop: '20px' }}>
            <div className="mb-4">
              <Row align="bottom" gutter={[20, 20]} justify="space-between">
                {show.coverImageUrl && (
                  <Col>
                    <ImageContainer pushTop={100}>
                      <Image src={show.coverImageUrl} radius={"5px"} />
                    </ImageContainer>
                  </Col>
                )}
                <Col>
                  <Div>
                    <Row singleRow gutter={10}>
                      {normalLinks.length + listenLinks.length > 0 && (
                        <Col>
                          <Popover
                            trigger={["click"]}
                            placement="bottom"
                            simple
                            content={
                              <ListenPopoverInner>
                                <Row gutter={12}>
                                  {
                                    listenLinks.length > 0 && (
                                      <Col>
                                        <ListenConnects
                                          title="Listen On"
                                          items={listenLinks}
                                        />
                                      </Col>
                                    )
                                  }
                                  {
                                    normalLinks.length > 0 && (
                                      <Col>
                                        <ListenConnects
                                          title="Connect"
                                          items={normalLinks}
                                        />
                                      </Col>
                                    )
                                  }
                                </Row>
                              </ListenPopoverInner>
                            }
                          >
                            <ListenButton>
                              <Row gutter={5} align="middle">
                                <Col>
                                  <FaExtraLinkAlt />
                                </Col>
                                <Col>links</Col>
                              </Row>
                            </ListenButton>
                          </Popover>
                        </Col>
                      )}
                      <Col>
                        {renderFollowButton()}
                      </Col>
                    </Row>
                  </Div>
                </Col>
              </Row>
            </div>
            {show.title && (
              <MobilePageTitle>{show.title}</MobilePageTitle>
            )}
          </div>
        )
      }
      <Modal title="Do you want to unfollow?" thin open={unfollowShowModalOpen} onCancel={() => setUnfollowShowModalOpen(false)} centered>
        <Flex gap={10}>
          <Button size="semi-middle" type="default" onClick={() => setUnfollowShowModalOpen(false)}>cancel</Button>
          <Button size="semi-middle" onClick={handleUnfollowConfirm} className='confirm-unfollow-btn'>confirm unfollow</Button>
        </Flex>
      </Modal>

      <Modal title="You can't unfollow with an active subscription" thin open={unfollowErrorSubscriptionModalOpen} onCancel={() => setUnfollowErrorSubscriptionModalOpen(false)} centered>
        <Flex gap={10}>
          <Button size="semi-middle" type="default" onClick={() => setUnfollowErrorSubscriptionModalOpen(false)}>close</Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default withPublicPageState(withTheme(FanPageHeader));
