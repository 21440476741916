import { gql } from '@apollo/client';
import { RewardFields, membershipFields } from 'graphql-api';
import { perkFields } from './Perk';

export const USER_QUERY = gql`
  query User($id: ID!) {
    user(id: $id) {
      firstName,
      lastName,
      email,
      twitter,
      instagram
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      firstName
      lastName
      bio
      email
      twitter
      instagram
      website
      avatarUrl
      type
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($firstName: String, $lastName: String, $displayName: String, $instagram: String, $twitter: String, $bio: String, $website: String) {
    updateUser(firstName: $firstName, lastName: $lastName, displayName: $displayName, instagram: $instagram, twitter: $twitter, website: $website, bio: $bio) {
      errors
      user {
        id
        firstName
        twitter
      }
    }
  }
`;

export const UPDATE_USER_AVATAR_MUTATION = gql`
  mutation updateUserAvatar($avatar: Upload!) {
    updateUserAvatar(avatar: $avatar) {
      success,
      error,
      user {
        avatarUrl
      }
    }
  }
`;

export const DELETE_USER_AVATAR_MUTATION = gql`
  mutation deleteUserAvatar {
    deleteUserAvatar {
      success
    }
  }
`;

export const UPDATE_EMAIL_MUTATION = gql`
  mutation updateEmail($currentPassword: String!, $email: String!) {
    updateEmail(currentPassword: $currentPassword, email: $email) {
      success,
      error,
      user {
        email
      }
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
      success,
      user {
        email
      }
    }
  }
`;

export const REMOVE_CANCELLED_BANNER = gql`
  mutation removeCancelledBanner {
    removeCancelledBanner {
      success,
      error,
      company {
        showCancelledBanner
      }
    }
  }
`;

export const USER_PAYMENTS_QUERY = gql`
  query userPayments {
    userPayments {
      amountCents
      date
      id
      refund
      source
      show {
        id
        slug
        title
        coverImageUrl
        owner {
          id
          displayName
        }
      }
      user {
        id
        avatarUrl
        displayName
        isMembership
      }
    }
  }
`;

export const USER_SHOW_PAYMENTS_QUERY = gql`
  query userShowPayments {
    userShowPayments {
      id
      rank
      show {
        id
        slug
        title
        coverImageUrl
        owner {
          id
          email
          displayName
          avatarUrl
          bio
          instagram
          twitter
        }
        rewards {
          ...rewardFields
        }
      }
      user {
        id
        avatarUrl
        displayName
      }
      membership {
        ...membershipFields
      }
    }
  }
  ${RewardFields}
  ${membershipFields}
`;
