import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { withPublicPageState } from 'apps/PublicPage/context';

import { useMutation, useQuery } from '@apollo/client';

import { Row, Col, Spacer, Logo, Button, Avatar, A, Modal, Popover, PopoverInner, Flexbox, Dropdown } from 'atoms';

import { SIGNUP, LOGIN, TRUE, AUTH_TOKEN_KEY } from 'consts';
import { LOGOUT_MUTATION } from 'apps/Auth/graphql';
import { avatarMenuItems } from './AvatarMenu';
import NotificationsWithPopover from 'apps/Dashboard/components/Notifications';
import { FAN_QUERY } from "graphql-api";
import { ConfigProvider } from 'antd';

const Nav = styled.nav`
    padding: 10px 0 5px;
`;

const AuthModalInner = styled.div`
    padding: 25px 0 20px;
`;

const MenuTitle = styled.div`
    margin-bottom: 5px;
`;
const MenuButton = styled(Button)`
    &, &[href] {
        padding: 10px 15px !important;
        margin-bottom: 6px;
        border-radius: 2px;
        line-height: 1.5715;
        ${props => props.type === 'default' && `
            border: 1px solid var(--color-border);
            color: var(--color-content);
            background-color: var(--color-body-background);
        `}
        :last-child {
            margin-bottom: 0;
        }
        span {
            line-height: 1.5715;
        }
    }
`;

const HeaderNav = ({
    publicPageState: {
        isLoggedIn,
        me,
        openAuthModal,
        showId
    }
}) => {
    const { data } = useQuery(FAN_QUERY, {
        variables: {
            userId: me?.id,
            showId
        },
        skip: !isLoggedIn || !me?.id || !showId
    });

    const isFan = !!data?.fan;

    const [triggerLogout] = useMutation(LOGOUT_MUTATION, {
        onCompleted() {
          localStorage.setItem(AUTH_TOKEN_KEY, '');
          location.reload();
        }
      });

    const handleMenuClick = ({ key }) => {
        switch (key) {
            case 'logout':
            triggerLogout({
                variables: {
                input: {}
                }
            });
            break;
            default:
            break;
        }
    }
        
    return (
        <>
            <Nav>
                <Row gutter={20} align={'middle'} singleRow>
                    <Col>
                        {isLoggedIn && isFan && (
                            <NotificationsWithPopover fanPageShow={showId} dashboard={false} />
                        )}
                    </Col>
                    <Spacer />
                    <Col>
                        {
                            isLoggedIn ? (
                                <ConfigProvider
                                    theme={{
                                        components: {
                                        Dropdown: {
                                            paddingBlock: 8,
                                        }
                                        },
                                        token: {
                                        controlPaddingHorizontal: 12,
                                        fontSizeSM: 16,
                                        }
                                    }}
                                    >
                                    <Dropdown
                                        menu={{
                                            items: avatarMenuItems,
                                            onClick: handleMenuClick
                                        }}
                                        arrow
                                        placement='bottomRight'
                                        trigger={['click']}
                                    >
                                        <div>
                                            <Avatar src={me.avatarUrl} css={`width: 50px; cursor: pointer;`} />
                                        </div>
                                    </Dropdown>
                                </ConfigProvider>
                                
                            ) : (
                                <Popover
                                    content={
                                        <PopoverInner css='padding: 20px 16px;'>
                                            <MenuTitle>Fanlist Account</MenuTitle>
                                            <MenuButton onClick={() => openAuthModal(LOGIN)} block type='default'>Login</MenuButton>
                                            <MenuButton onClick={() => openAuthModal(SIGNUP)} block>Sign up</MenuButton>
                                        </PopoverInner>
                                    }
                                    width='190px'
                                    simple
                                    arrow
                                    placement='bottomRight'
                                    trigger={['click']}
                                >
                                    <Flexbox center css='width: 50px; height: 50px; cursor: pointer;'>
                                        <Logo small />
                                    </Flexbox>
                                </Popover>
                            )
                        }
                    </Col>
                </Row>
            </Nav>
        </>
    )
};

export default withPublicPageState(HeaderNav);
