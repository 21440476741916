import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/client";

import { withDashboardState } from "apps/Dashboard/context";

import {
  USER_NOTIFICATIONS_QUERY,
  UPDATE_USER_NOTIFICATIONS_MUTATION,
} from "apps/Dashboard/graphql";

import {
  PageCard,
  CardInner,
  Button,
  CardTitle,
  Row,
  Col,
  Switch,
  Form,
  FormItem,
  SkeletonCard,
  message,
} from "atoms";
import { FAN, TRUE } from "consts";

const NotificationRow = styled.div`
  margin-bottom: 15px;
`;

const StyledPageCard = styled(PageCard)`
  width: 100%;
  
  ${Col}:nth-child(2) {
    flex: 1;
  }
`;

const Notifications = ({
  showId,
}) => {
  const { data, loading, error } = useQuery(USER_NOTIFICATIONS_QUERY, {
    variables: { showId },
    fetchPolicy: 'network-only', // Force refetch when showId changes
  });
  const userNotifications = data?.userNotifications;
  const {
    userTipConfirmations,
    userNewLike,
    userNewReply,
    userNewPerkBought,
    userNewPaymentReply,
    userEpisodeEmailAnnouncement,
    userPerkEmailAnnouncement,
    userNewChatroomMessageReply,
    userNewChatroomMessageLike,
    userNewChatroomMessageMention,
  } = userNotifications || {};

  // Track local state for each notification type, keyed by showId
  const [localNotifications, setLocalNotifications] = useState({});

  // Update local state when data changes or showId changes
  React.useEffect(() => {
    if (userNotifications && showId) {
      setLocalNotifications(prev => {
        const newState = { ...prev };
        newState[showId] = {
          userTipConfirmations,
          userNewLike,
          userNewReply,
          userNewPerkBought,
          userNewPaymentReply,
          userEpisodeEmailAnnouncement,
          userPerkEmailAnnouncement,
          userNewChatroomMessageReply,
          userNewChatroomMessageLike,
          userNewChatroomMessageMention,
        };
        return newState;
      });
    }
  }, [userNotifications, showId]);

  const [triggerUpdateNotification] = useMutation(
    UPDATE_USER_NOTIFICATIONS_MUTATION,
    {
      onCompleted({ updateUserNotifications: { success, error } }) {
        if (success === TRUE) {
          message.success("Notifications updated");
        } else {
          message.error("Failed to update notifications");
        }
      },
      refetchQueries: [
        {
          query: USER_NOTIFICATIONS_QUERY,
          variables: { showId }
        }
      ]
    }
  );

  const handleNotificationChange = (key, value) => {
    if (!showId) return;

    // Update local state immediately for specific show
    setLocalNotifications(prev => {
      const showSettings = prev[showId] || {};
      return {
        ...prev,
        [showId]: {
          ...showSettings,
          [key]: value
        }
      };
    });

    // Send update to server
    triggerUpdateNotification({
      variables: {
        [key]: value,
        showId,
      }
    }).catch(err => {
      // Revert local state on error
      setLocalNotifications(prev => {
        const showSettings = prev[showId] || {};
        return {
          ...prev,
          [showId]: {
            ...showSettings,
            [key]: !value
          }
        };
      });
      message.error("Failed to update notification settings");
    });
  };

  if (loading) {
    return <SkeletonCard title={"Your Email Notifications"} />;
  }

  if (error) {
    return <PageCard><CardInner>Error loading notification settings</CardInner></PageCard>;
  }

  const currentShowNotifications = localNotifications[showId] || userNotifications || {};

  return (
    <StyledPageCard>
      <CardInner>
        <CardTitle>Email Notifications for This Page</CardTitle>
      </CardInner>
      <CardInner>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewReply}
                onChange={(e) => handleNotificationChange('userNewReply', e)}
              />
            </Col>
            <Col>Fan mail replies</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewLike}
                onChange={(e) => handleNotificationChange('userNewLike', e)}
              />
            </Col>
            <Col>Fan mail likes</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewPaymentReply}
                onChange={(e) => handleNotificationChange('userNewPaymentReply', e)}
              />
            </Col>
            <Col>Tip replies</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userTipConfirmations}
                onChange={(e) => handleNotificationChange('userTipConfirmations', e)}
              />
            </Col>
            <Col>Tip confirmations</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewPerkBought}
                onChange={(e) => handleNotificationChange('userNewPerkBought', e)}
              />
            </Col>
            <Col>Confirmation when you buy an item</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userPerkEmailAnnouncement}
                onChange={(e) => handleNotificationChange('userPerkEmailAnnouncement', e)}
              />
            </Col>
            <Col>When new shop items are added</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userEpisodeEmailAnnouncement}
                onChange={(e) => handleNotificationChange('userEpisodeEmailAnnouncement', e)}
              />
            </Col>
            <Col>Episode announcements</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewChatroomMessageLike}
                onChange={(e) => handleNotificationChange('userNewChatroomMessageLike', e)}
              />
            </Col>
            <Col>When you receive a like in the chatroom</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewChatroomMessageReply}
                onChange={(e) => handleNotificationChange('userNewChatroomMessageReply', e)}
              />
            </Col>
            <Col>When you receive a reply in the chatroom</Col>
          </Row>
        </NotificationRow>
        <NotificationRow>
          <Row gutter={16} align={"middle"}>
            <Col>
              <Switch
                checked={currentShowNotifications.userNewChatroomMessageMention}
                onChange={(e) => handleNotificationChange('userNewChatroomMessageMention', e)}
              />
            </Col>
            <Col>When you receive an @mention in the chatroom</Col>
          </Row>
        </NotificationRow>
      </CardInner>
    </StyledPageCard>
  );
};

export default withDashboardState(Notifications);
