import { gql } from "@apollo/client";

export const TOGGLE_DELETE_PAYMENT_MESSAGE_MUTATION = gql`
  mutation toggleDeletePaymentMessage($messageId: ID) {
    toggleDeletePaymentMessage(messageId: $messageId) {
      success,
      error
    }
  }
`;

export const TOGGLE_DELETE_PAYMENT_REPLY_MUTATION = gql`
  mutation toggleDeletePaymentReply($replyId: ID) {
    toggleDeletePaymentReply(replyId: $replyId) {
      success,
      error
    }
  }
`;

export const UPDATE_PAYMENT_MESSAGE_MUTATION = gql`
  mutation updatePaymentMessage($messageId: ID, $content: String) {
    updatePaymentMessage(messageId: $messageId, content: $content) {
      paymentMessage {
        id
        content
        createdAt
        status
      },
      success,
      error
    }
  }
`;

export const UPDATE_PAYMENT_REPLY_MUTATION = gql`
  mutation updatePaymentReply($replyId: ID, $paymentId: ID, $content: String) {
    updatePaymentReply(replyId: $replyId, paymentId: $paymentId, content: $content) {
      paymentReply {
        id
        content
        createdAt
        status
        user {
          id
          displayName
          bio
          avatarUrl
          website
          twitter
          instagram
          isMembership
        }
      },
      success,
      error
    }
  }
`;

export const ACCESS_MEMBERSHIP_PAYMENT_MUTATION = gql`
  mutation accessPayment(
    $showId: ID!,
    $tierId: ID!,
    $token: String
  ) {
    accessPayment(
      showId: $showId,
      tierId: $tierId,
      token: $token
    ) {
      show {
        id
        membership {
          id
          amountCents
          accessType
          createdAt
          canceledAt
          cancelRequestedAt
          status
          user {
            id
          }
        }
      }
      success
      error
    }
  }
`;

export const CANCEL_MEMBERSHIP_MUTATION = gql`
  mutation cancelMembership(
    $membershipId: ID!
  ) {
    cancelMembership(
      membershipId: $membershipId
    ) {
      membership {
        id
        status
        accessType
        canceledAt
        cancelRequestedAt
      }
      success
      error
    }
  }
`;
