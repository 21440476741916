import { gql } from '@apollo/client';
import { contactFields, fanFields, membershipFields } from 'graphql-api';

export const CONTACTS_QUERY = gql`
  query contacts {
    contacts {
      ...contactFields
    }
  }
  ${contactFields}
`;

export const FANS_QUERY = gql`
  query fansByFilters(
    $page: Int
    $limit: Int
    $search: String
    $socialFilters: [String!]
    $sortBy: String
    $sortDirection: String
  ) {
    fansByFilters(
      page: $page
      limit: $limit
      search: $search
      socialFilters: $socialFilters
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      collection {
        ...fanFields
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${fanFields}
`;

export const MEMBERSHIPS_QUERY = gql`
  query memberships(
    $page: Int
    $limit: Int
    $search: String
    $subscriptionFilters: [String!]
    $sortDirection: String
  ) {
    memberships(
      page: $page
      limit: $limit
      search: $search
      subscriptionFilters: $subscriptionFilters
      sortDirection: $sortDirection
    ) {
      collection {
        ...membershipFields
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
  ${membershipFields}
`;

export const MEMBERS_QUERY = gql`
  query members {
    members {
      id
      contactId
      status
    }
  }
`;

export const ACTIVE_MEMBERSHIPS_COUNT_QUERY = gql`
  query activeMembershipsCount {
    activeMembershipsCount {
      activeMembersCount
      show {
        id
        slug
      }
    }
  }
`;

export const GIFT_MEMBERSHIP_MUTATION = gql`
  mutation giftMembership($fanId: ID!) {
    giftMembership(fanId: $fanId) {
      success
      error
    }
  }
`;

export const TOGGLE_MEMBERS_CHATROOM_MUTATION = gql`
  mutation toggleMembersChatroom($chatRoomForMembers: Boolean!) {
    toggleMembersChatroom(chatRoomForMembers: $chatRoomForMembers) {
      show {
        id
        chatRoomForMembers
        finishedMembershipSetup
      }
      success
      error
    }
  }
`;

export const TOGGLE_ARCHIVE_CONTACT_MUTATION = gql`
  mutation toggleArchiveContact($contactId: ID) {
    toggleArchiveContact(contactId: $contactId) {
      success,
      error
    }
  }
`;

export const TOGGLE_SUSPEND_FAN_MUTATION = gql`
  mutation toggleSuspendFan($fanId: ID) {
    toggleSuspendFan(fanId: $fanId) {
      fan {
        id,
        suspended
      },
      success,
      error
    }
  }
`;

export const MANUAL_CANCEL_MEMBERSHIP_MUTATION = gql`
  mutation cancelFansMembership($membershipId: ID!) {
    cancelFansMembership(membershipId: $membershipId) {
      membership {
        id,
        status
        canceledAt
        cancelRequestedAt
      },
      success,
      error
    }
  }
`;
