import React from 'react';
import styled from 'styled-components';

import { MenuProps } from 'antd';
import { Link, A } from 'atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHeart, faCog, faDesktop } from '@fortawesome/free-solid-svg-icons';

const MenuLink = styled(Link)`
  color: #000;
`;
const MenuIcon = styled(FontAwesomeIcon)`
  && {
  }
  .ant-dropdown-menu-item:hover && {
    color: var(--color-primary);
  }
`;

const SIconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 18px;
  justify-content: center;
  align-items: center;
`;

export const avatarMenuItems: MenuProps['items'] = [
  {
    key: 'dashboard',
    label: (
      <A href={'/'}>
        dashboard
      </A>
    ),
    icon: <SIconContainer><MenuIcon icon={faDesktop} /></SIconContainer>
  },
  {
    key: 'following',
    label: (
      <A href={'/following/following'}>
        following
      </A>
    ),
    icon: <SIconContainer><MenuIcon icon={faHeart} /></SIconContainer>
  },
  {
    key: 'account-settings',
    label: (
      <A href={'/account-settings/profile'}>
        account settings
      </A>
    ),
    icon: <SIconContainer><MenuIcon icon={faCog} /></SIconContainer>
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <SIconContainer><MenuIcon icon={faSignOutAlt} /></SIconContainer>
  },
];
