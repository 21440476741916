import React from 'react';
import {useMemo} from 'react';
import styled from 'styled-components';
import { PublicPerkType, User } from 'types';
import PerkCard from './PerkCard';
import { Link } from 'atoms';

const SPerkList = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 15px 30px;
  flex-wrap: wrap;
`;

const SPerkCard = styled.div`  
  @media screen and (min-width: 350px) {
    flex: 0 1 100%;
    max-width: 100%
  }

  @media screen and (min-width: 780px) {
    flex: 0 1 48%;
    max-width: 48%
  }
  
  @media screen and (min-width: 1150px) {
    flex: 0 1 31%;
    max-width: 31%
  }
`;

type PropsType = {
  perks: PublicPerkType[];
  isLoggedIn: boolean;
  superfansPluralName: string;
  superfansSingularName: string;
  show_slug: string;
  showPurchasedFansOnPerk: boolean;
  showPurchasesNumberOnPerk: boolean;
  me: User,
  onPerkCreate: () => void;
}

const PerkList = (props: PropsType): JSX.Element => {
  const {perks, ...forwardProps} = props;

  return (
    <SPerkList>
      {
        perks.map((perk) => (
          <SPerkCard key={perk.id} className='mb-3'>
              <PerkCard
                perk={perk}
                imageOptional
                {...forwardProps}
              />
          </SPerkCard>
        ))
      }
    </SPerkList>
  )
}

export default PerkList;
